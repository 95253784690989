import React, { useEffect, useState } from "react";

import { getCapitalizedString, useDropdowns, visitorAlert } from "@mchm/common";

import useList from "../../Hooks/useList";

import {
 Backdrop,
 Button,
 Fetcher,
 PageContent,
 SearchBox,
 Toolbar,
} from "@mchm/common";

import PageMenu from "./PageMenu";
import List from "../../Components/List";
import ListFinalizationModal from "./ListFinalizationModal";

import { CoordsInterface } from "@mchm/common";
import { ProductInterface } from "../../SharedTypes";

interface ListPageProps {
 listVariant: "shop" | "compose";
}

const ListPage = ({ listVariant }: ListPageProps) => {
 const [isPageMenuVisible, setIsPageMenuVisible] = useState(false);
 const [isListFinalizationModalVisible, setIsListFinalizationModalVisible] =
  useState(false);
 const [contextMenuTriggerCoords, setContextMenuTriggerCoords] =
  useState<CoordsInterface>({ x: 0, y: 0 });
 const [searchPhrase, setSearchPhrase] = useState<string>("");
 const [isInSearchMode, setIsInSearchMode] = useState(false);

 const {
  products,
  storeFetchedProducts,
  getCategoriesList,
  getSearchResult,
  getAreAllProductsFromCategoryInCart,
  getAreAllProductsInCart,
  handleProductSelection,
  finalizeList,
 } = useList(listVariant);

 const {
  dropdownsState,
  addDropdowns,
  toggleDropdown,
  expandAllDropdowns,
  collapseAllDropdowns,
 } = useDropdowns();

 useEffect(() => {
  visitorAlert("shoppin", listVariant);
 }, [listVariant]);

 const onFetched = (data: Record<string, ProductInterface[]>) => {
  storeFetchedProducts(data);
  const allCategories = Object.keys(data);
  addDropdowns(allCategories);
 };

 return (
  <>
   <Backdrop
    visible={
     isPageMenuVisible === true || isListFinalizationModalVisible === true
    }
    onClick={() => {
     setIsPageMenuVisible(false);
     setIsListFinalizationModalVisible(false);
    }}
   />
   <PageMenu
    listVariant={listVariant}
    visible={isPageMenuVisible}
    contextMenuTriggerCoords={contextMenuTriggerCoords}
    closeMenu={() => setIsPageMenuVisible(false)}
    enableSearchMode={() => setIsInSearchMode(true)}
    expandAllCategpries={expandAllDropdowns}
    collapseAllCategories={collapseAllDropdowns}
    showListFinalizationModal={() => setIsListFinalizationModalVisible(true)}
   />
   <ListFinalizationModal
    visible={isListFinalizationModalVisible}
    finalizeList={finalizeList}
    getAreAllProductsInCart={getAreAllProductsInCart}
    closeModal={() => setIsListFinalizationModalVisible(false)}
   />
   {isInSearchMode === true && (
    <Toolbar closeAction={() => setIsInSearchMode(false)} />
   )}
   {isInSearchMode === false && (
    <Toolbar
     backPath="/"
     backLabel="Shoppin"
     onEllipsisClick={
      Object.keys(products ?? {}).length === 0
       ? undefined
       : (event) => {
          setIsPageMenuVisible(true);
          setContextMenuTriggerCoords({ x: event.clientX, y: event.clientY });
         }
     }
    />
   )}
   <PageContent withNav>
    {isInSearchMode === true && (
     <SearchBox
      onChange={(value: string) => setSearchPhrase(value)}
      placeholder="Search"
     />
    )}
    {isInSearchMode === false && <h1>{getCapitalizedString(listVariant)}</h1>}
    <Fetcher
     isDataFetchNeeded={products === null}
     url={`${process.env.REACT_APP_BACKEND_URL}/products/forList/${listVariant}`}
     onFetched={(data) => {
      onFetched(data);
     }}
     dataName="list"
    />
    {products !== null && Object.keys(products).length !== 0 && (
     <List
      listVariant={listVariant}
      products={products}
      isInSearchMode={isInSearchMode}
      searchPhrase={searchPhrase}
      dropdownsState={dropdownsState}
      getSearchResult={getSearchResult}
      toggleDropdown={toggleDropdown}
      getCategoriesList={getCategoriesList}
      getAreAllProductsFromCategoryInCart={getAreAllProductsFromCategoryInCart}
      handleProductSelection={handleProductSelection}
     />
    )}
    {products !== null && Object.keys(products).length === 0 && (
     <>
      <span>No items added yet</span>
      <Button href={listVariant === "shop" ? "/compose" : "/addProduct"}>
       Add products
      </Button>
     </>
    )}
   </PageContent>
  </>
 );
};

export default ListPage;
