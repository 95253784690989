import React from "react";

import { ContextMenu } from "@mchm/common";

import { CoordsInterface } from "@mchm/common";

interface PageMenuProps {
 listVariant: "compose" | "shop";
 visible: boolean;
 contextMenuTriggerCoords: CoordsInterface;
 closeMenu: () => void;
 enableSearchMode: () => void;
 expandAllCategpries: () => void;
 collapseAllCategories: () => void;
 showListFinalizationModal: () => void;
}

const PageMenu = ({
 listVariant,
 visible,
 contextMenuTriggerCoords,
 closeMenu,
 enableSearchMode,
 expandAllCategpries,
 collapseAllCategories,
 showListFinalizationModal,
}: PageMenuProps) => {
 const menuItems = [
  {
   name: "Search",
   action: () => {
    enableSearchMode();
    closeMenu();
   },
  },
  {
   name: "Expand all",
   action: () => {
    expandAllCategpries();
    closeMenu();
   },
  },
  {
   name: "Collapse all",
   action: () => {
    collapseAllCategories();
    closeMenu();
   },
  },
 ];

 if (listVariant === "shop") {
  menuItems.push({
   name: "Done",
   action: () => {
    showListFinalizationModal();
    closeMenu();
   },
  });
 }

 return (
  <ContextMenu
   visible={visible}
   menuItems={menuItems}
   contextMenuTriggerCoords={contextMenuTriggerCoords}
  />
 );
};

export default PageMenu;
