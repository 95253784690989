import React from "react";
import { useParams } from "react-router-dom";

import { visitorAlert } from "@mchm/common";
import { PageContent, Button } from "@mchm/common";

const Home = () => {
 const { visitSource } = useParams();

 visitorAlert("shoppin", `home-${visitSource ?? ""}`);

 return (
  <PageContent withNav={false}>
   <h1>Shoppin</h1>
   <Button href="/compose/">Compose</Button>
   <Button href="/shop/">Shop</Button>
   <Button href="/addProduct/">Add product</Button>
   <Button href="/editProduct/">Edit product</Button>
   <Button href="/settings/">Settings</Button>
  </PageContent>
 );
};

export default Home;
