import { useCallback, useEffect, useState } from "react";
import Axios from "axios";

import { ProductInterface, StateSwitchType } from "../SharedTypes";

const useList = (listVariant: "compose" | "shop") => {
 const [products, setProducts] = useState<Record<
  string,
  ProductInterface[]
 > | null>(null);

 useEffect(() => {
  setProducts(null);
 }, [listVariant]);

 const stateSwitch: StateSwitchType =
  listVariant === "shop" ? "inCart" : "onList";

 const sorter = useCallback(
  (a: ProductInterface, b: ProductInterface) => {
   if (a[stateSwitch] && !b[stateSwitch]) return 1;
   if (!a[stateSwitch] && b[stateSwitch]) return -1;
   if (a.name > b.name) return 1;
   if (a.name < b.name) return -1;
   return 0;
  },
  [stateSwitch]
 );

 const storeFetchedProducts = useCallback(
  (data: Record<string, ProductInterface[]>) => {
   Object.values(data).forEach((category) => {
    category.sort((a, b) => sorter(a, b));
   });
   setProducts(data);
  },
  [sorter]
 );

 const flattenedList = () => {
  if (products !== null) {
   const result = Object.values(products).reduce((acc, category) => {
    acc.push(...category);
    return acc;
   }, []);
   result.sort((a, b) => {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
   });
   return result;
  } else {
   return [];
  }
 };

 const handleProductSelection = (productName: string) => {
  const selectedProduct = flattenedList().filter(
   (product) => product.name === productName
  )[0];

  Axios.post(
   `${process.env.REACT_APP_BACKEND_URL}/products/updateOne/${listVariant}`,
   {
    name: selectedProduct.name,
    [stateSwitch]: !selectedProduct[stateSwitch],
   }
  ).catch((error) => console.log(error));

  setProducts((current) => {
   if (current !== null) {
    const mutableArray = current[selectedProduct.category];
    const index = mutableArray.indexOf(selectedProduct);
    mutableArray[index][stateSwitch] = !mutableArray[index][stateSwitch];
    mutableArray.sort((a, b) => sorter(a, b));
    return { ...current, [selectedProduct.category]: mutableArray };
   } else {
    return null;
   }
  });
 };

 const getAreAllProductsFromCategoryInCart = (category: string) => {
  if (products !== null) {
   const result = products[category].reduce((acc, product) => {
    return acc && product.inCart;
   }, true);

   return result;
   // products[category].filter((product) => product.inCart === true).length === 0
  } else {
   return false;
  }
 };

 const getAreAllProductsInCart = () => {
  if (products !== null) {
   const allProductsOnList = Object.values(products).reduce(
    (acc, productsInCategory) => {
     return [...acc, ...productsInCategory];
    },
    []
   );

   const result = allProductsOnList.reduce((acc, product) => {
    return acc && product.inCart;
   }, true);

   return result;
  } else {
   return false;
  }
 };

 const getCategoriesList = () => {
  if (products !== null) {
   if (listVariant === "compose") {
    return Object.keys(products);
   }

   if (listVariant === "shop") {
    return Object.keys(products).sort((a, b) => {
     if (
      getAreAllProductsFromCategoryInCart(a) &&
      !getAreAllProductsFromCategoryInCart(b)
     )
      return 1;
     if (
      !getAreAllProductsFromCategoryInCart(a) &&
      getAreAllProductsFromCategoryInCart(b)
     )
      return -1;
     if (a > b) return 1;
     if (a < b) return -1;
     return 0;
    });
   }
   return [];
  } else {
   return [];
  }
 };

 const getSearchResult = (searchPhrase: string) => {
  if (products !== null && searchPhrase !== null) {
   return flattenedList().filter((product) =>
    product.name.toLowerCase().includes(searchPhrase.toLowerCase())
   );
  } else {
   return [];
  }
 };

 const finalizeList = (ommitProductsNotInCart: boolean) => {
  let itemsToOmmit: string[] = [];

  if (ommitProductsNotInCart) {
   itemsToOmmit = flattenedList()
    .filter((product) => product.inCart === false)
    .map((product) => product.name);
  }

  Axios.post(
   `${process.env.REACT_APP_BACKEND_URL}/products/finalizeList`,
   itemsToOmmit
  )
   .then((response) => {
    const updatedProducts = response.data;
    storeFetchedProducts(updatedProducts);
    return;
   })
   .catch(() => {
    //TODO handle
    return;
   });
 };

 return {
  products,
  storeFetchedProducts,
  handleProductSelection,
  getCategoriesList,
  getAreAllProductsFromCategoryInCart,
  getAreAllProductsInCart,
  getSearchResult,
  finalizeList,
 };
};

export default useList;
