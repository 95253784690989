//ToDo:When adding new product name gets cleared and error “required”appears

import React, { useState, useEffect } from "react";

import "./AddProduct.css";

import {
 useFormData,
 useFormSubmissionState,
 useFormValidationState,
 visitorAlert,
} from "@mchm/common";
import {
 Button,
 Checkbox,
 ComboBox,
 Fetcher,
 Input,
 Snackbar,
 PageContent,
 Toolbar,
} from "@mchm/common";

import { FormValidationCriteria } from "@mchm/common";

const AddProduct = () => {
 const { formData, formDataChangeHandle } = useFormData({
  name: "",
  category: "",
  onList: false,
  inCart: false,
 });

 const { formSubmissionState, submissionHandle } = useFormSubmissionState(
  formData,
  `${process.env.REACT_APP_BACKEND_URL}/products/create`
 );

 const formValidationCriteria: FormValidationCriteria = {
  name: ["required"],
  category: ["required"],
 };

 const { isEntireFormValid } = useFormValidationState(
  formData,
  formValidationCriteria
 );

 const [categories, setCategories] = useState<string[] | null>(null);

 useEffect(() => {
  visitorAlert("shoppin", "addProduct");
 }, []);

 return (
  <>
   <Toolbar
    backLabel="Shoppin"
    backPath="/"
   />
   <PageContent
    withNav
    className="add-product"
   >
    <h1>Add product</h1>
    <Fetcher
     isDataFetchNeeded={categories === null}
     url={`${process.env.REACT_APP_BACKEND_URL}/categories/`}
     onFetched={(data) => setCategories(data)}
     dataName="categories"
    />

    {categories !== null && (
     <form
      onSubmit={(event) => {
       formDataChangeHandle("", "name");
       submissionHandle(event);
      }}
     >
      <fieldset>
       <legend style={{ display: "none" }}>Add product</legend>
       <Input
        fieldName="name"
        label="Name"
        formData={formData}
        onValueChange={formDataChangeHandle}
        formValidationCriteria={formValidationCriteria}
       />
       <ComboBox
        fieldName="category"
        label="Category"
        formData={formData}
        options={categories}
        onValueChange={formDataChangeHandle}
        formValidationCriteria={formValidationCriteria}
       />
       <Checkbox
        fieldName="onList"
        label="Add to list"
        checked={formData.onList}
        onValueChange={formDataChangeHandle}
       />
      </fieldset>
      <Button
       type="submit"
       disabled={
        formSubmissionState.submitting === true || isEntireFormValid === false
       }
      >
       Submit
      </Button>
     </form>
    )}

    <Snackbar formSubmissionState={formSubmissionState} />
   </PageContent>
  </>
 );
};

export default AddProduct;
