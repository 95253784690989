import React from "react";

import { Modal } from "@mchm/common";

interface ListFinalizationModalProps {
 visible: boolean;
 getAreAllProductsInCart: () => boolean;
 finalizeList: (ommitProductsNotInCart: boolean) => void;
 closeModal: () => void;
}

const ListFinalizationModal = ({
 visible,
 getAreAllProductsInCart,
 finalizeList,
 closeModal,
}: ListFinalizationModalProps) => {
 if (visible === true) {
  const areAllProductsInCart = getAreAllProductsInCart();

  if (areAllProductsInCart === false) {
   return (
    <Modal
     visible={true}
     message={[
      "There are unchecked items on the list.",
      "Save them for next one?",
     ]}
     onConfirm={() => {
      finalizeList(true);
      closeModal();
     }}
     onDecline={() => {
      finalizeList(false);
      closeModal();
     }}
     onCancel={() => closeModal()}
    />
   );
  } else {
   return (
    <Modal
     visible={true}
     message={["Are you sure?"]}
     onConfirm={() => {
      finalizeList(false);
      closeModal();
     }}
     onCancel={() => closeModal()}
    />
   );
  }
 } else {
  return null;
 }
};

export default ListFinalizationModal;
