import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { useRegularVision } from "@mchm/common";
import { StylingProvider } from "@mchm/common";

import Home from "./Pages/Home";
import ListPage from "./Pages/ListPage/ListPage";
import AddProduct from "./Pages/AddProduct";
import EditProduct from "./Pages/EditProduct";
import EditProductForm from "./Pages/EditProductForm";
import Settings from "./Pages/Settings";

const App = () => {
 const { regularVisionOn, regularVisionSwitch } = useRegularVision();

 return (
  <StylingProvider regularVisionOn={regularVisionOn}>
   {/* <nav>Menu</nav> */}
   <Router>
    <Routes>
     <Route
      path="/:visitSource?"
      element={<Home />}
     />
     <Route
      path="/compose/"
      element={<ListPage listVariant="compose" />}
     />
     <Route
      path="/shop/"
      element={<ListPage listVariant="shop" />}
     />
     <Route
      path="/addProduct/"
      element={<AddProduct />}
     />
     <Route
      path="/editProduct/"
      element={<EditProduct />}
     />
     <Route
      path="/editProduct/:productName"
      element={<EditProductForm />}
     />
     <Route
      path="/settings/"
      element={
       <Settings
        regularVisionSwitch={regularVisionSwitch}
        regularVisionOn={regularVisionOn}
       />
      }
     />
    </Routes>
   </Router>
  </StylingProvider>
 );
};

export default App;
