import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "axios";

import {
 useFormData,
 useFormSubmissionState,
 useFormValidationState,
} from "@mchm/common";
import {
 Button,
 ComboBox,
 Fetcher,
 Input,
 PageContent,
 Snackbar,
 Toolbar,
} from "@mchm/common";

import { ProductInterface } from "../SharedTypes";
import { FormValidationCriteria } from "@mchm/common";

const EditProductForm = () => {
 const { productName } = useParams();

 const navigateTo = useNavigate();

 const [product, setProduct] = useState<ProductInterface | null>(null);

 const [categories, setCategories] = useState<string[] | null>(null);

 const { formData, formDataChangeHandle } = useFormData({
  name: "",
  category: "",
 });

 const { formSubmissionState, submissionHandle } = useFormSubmissionState(
  formData,
  `${process.env.REACT_APP_BACKEND_URL}/products/edit/${productName}`
 );

 const formValidationCriteria: FormValidationCriteria = {
  name: ["required"],
  category: ["required"],
 };

 const { isEntireFormValid } = useFormValidationState(
  formData,
  formValidationCriteria
 );

 const onProductFetched = useCallback(
  (data: ProductInterface) => {
   setProduct(data);
   formDataChangeHandle(data.name, "name");
   formDataChangeHandle(data.category, "category");
  },
  [formDataChangeHandle]
 );

 const onDelete = useCallback(() => {
  Axios.delete(
   `${process.env.REACT_APP_BACKEND_URL}/products/${productName}`
  ).then(() => navigateTo("/editProduct/"));
 }, [navigateTo, productName]);

 return (
  <>
   <Toolbar
    backPath="/editProduct/"
    backLabel="Back"
   />
   <PageContent withNav={true}>
    <Fetcher
     isDataFetchNeeded={product === null}
     onFetched={onProductFetched}
     url={`${process.env.REACT_APP_BACKEND_URL}/products/${productName}`}
    />
    <Fetcher
     isDataFetchNeeded={categories === null}
     url={`${process.env.REACT_APP_BACKEND_URL}/categories/`}
     onFetched={(data) => setCategories(data)}
     dataName="categories"
    />
    {categories !== null && product !== null && (
     <form onSubmit={(event) => submissionHandle(event)}>
      <fieldset>
       <Input
        fieldName="name"
        formData={formData}
        label="Name"
        onValueChange={formDataChangeHandle}
        formValidationCriteria={formValidationCriteria}
       />
       <ComboBox
        fieldName="category"
        formData={formData}
        label="Category"
        onValueChange={formDataChangeHandle}
        options={categories}
        formValidationCriteria={formValidationCriteria}
       />
      </fieldset>
      <Button
       type="submit"
       disabled={
        formSubmissionState.submitting === true || isEntireFormValid === false
       }
      >
       Submit
      </Button>
      <Button
       type="button"
       variant="negative"
       onClick={() => onDelete()}
      >
       Delete
      </Button>
     </form>
    )}
    <Snackbar formSubmissionState={formSubmissionState} />
   </PageContent>
  </>
 );
};

export default EditProductForm;
