import { useCallback, useState } from "react";

import { Button, Fetcher, PageContent, SearchBox, Toolbar } from "@mchm/common";

import { ProductInterface } from "../SharedTypes";

const EditProduct = () => {
 const [searchPhrase, setSearchPhrase] = useState("");

 const [products, setProducts] = useState<string[] | null>(null);

 const onFetched = useCallback((data: ProductInterface[]) => {
  const productNames = data.reduce((acc: string[], cur) => {
   acc.push(cur.name);
   return acc;
  }, []);

  setProducts(productNames);
 }, []);

 return (
  <>
   <Toolbar
    backLabel="Shoppin"
    backPath="/"
   />
   <PageContent withNav={true}>
    <SearchBox
     onChange={(phrase) => setSearchPhrase(phrase)}
     placeholder="Search..."
    />
    <Fetcher
     isDataFetchNeeded={products === null}
     onFetched={onFetched}
     url={`${process.env.REACT_APP_BACKEND_URL}/products/all/`}
    />
    <ul className="flex-column">
     {products?.map((product) => {
      return product.toLowerCase().includes(searchPhrase.toLowerCase()) ? (
       <li key={product}>
        <Button href={`/editProduct/${product}`}>{product}</Button>
       </li>
      ) : null;
     })}
    </ul>
   </PageContent>
  </>
 );
};

export default EditProduct;
