import React from "react";

import { CategoryWrapper, SelectableCard } from "@mchm/common";

import { ProductInterface } from "../SharedTypes";

interface ListProps {
 listVariant: "shop" | "compose";
 products: Record<string, ProductInterface[]>;
 dropdownsState: Record<string, boolean>;
 isInSearchMode: boolean;
 searchPhrase: string;
 toggleDropdown: (dropdownName: string) => void;
 getSearchResult: (searchPhrase: string) => ProductInterface[];
 getCategoriesList: () => string[];
 getAreAllProductsFromCategoryInCart: (category: string) => boolean;
 handleProductSelection: (productName: string) => void;
}

const List = ({
 listVariant,
 products,
 dropdownsState,
 isInSearchMode,
 searchPhrase,
 toggleDropdown,
 getSearchResult,
 getCategoriesList,
 getAreAllProductsFromCategoryInCart,
 handleProductSelection,
}: ListProps) => {
 let productStateSwitch: "onList" | "inCart";

 if (listVariant === "compose") {
  productStateSwitch = "onList";
 } else {
  productStateSwitch = "inCart";
 }

 if (isInSearchMode === false) {
  return (
   <ul className="flex-column">
    {getCategoriesList().map((category) => (
     <CategoryWrapper
      category={category}
      key={category}
      crossedOut={
       listVariant === "shop"
        ? getAreAllProductsFromCategoryInCart(category)
        : false
      }
      expanded={dropdownsState[category]}
      onClick={() => {
       toggleDropdown(category);
      }}
     >
      {products[category].map((product) => {
       return (
        <SelectableCard
         key={product.name}
         onClick={() => handleProductSelection(product.name)}
         selected={product[productStateSwitch]}
        >
         {product.name}
        </SelectableCard>
       );
      })}
     </CategoryWrapper>
    ))}
   </ul>
  );
 } else {
  return (
   <ul className="flex-column">
    {getSearchResult(searchPhrase).map((product) => (
     <SelectableCard
      key={product.name}
      onClick={() => handleProductSelection(product.name)}
      selected={product[productStateSwitch]}
     >
      {product.name}
     </SelectableCard>
    ))}
   </ul>
  );
 }
};

export default List;
